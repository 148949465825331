import React from "react"
import CategoryWrapper from "../../components/CategoryWrapper"

function MenShirtsPage() {
  return (
    <CategoryWrapper
      seoTitle="Skjorter"
      gender="herre"
      category="shirts"
    />
  )
}

export default MenShirtsPage
